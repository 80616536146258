/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
/* Icon Bob */
@keyframes bob {
  0% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(6px);
  }
}

@keyframes bob-float {
  100% {
    transform: translateX(6px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 5vh, 0);
    transform: translate3d(0, 5vh, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.component-timeline {
  display: flex;
  height: calc(100vh - 163px);
  position: relative;
  font-size: 10px;
}

@media (max-width: 991px) {
  .component-timeline {
    display: block;
    height: auto;
  }
}

.component-timeline .scroll-container {
  display: flex;
  overflow: auto;
  min-width: 100%;
}

@media (max-width: 991px) {
  .component-timeline .scroll-container {
    display: block;
  }
}

.component-timeline .timeline-enter {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
}

.component-timeline .timeline-enter:nth-of-type(1) {
  animation-delay: 0.2s;
}

.component-timeline .timeline-enter:nth-of-type(2) {
  animation-delay: 0.4s;
}

.component-timeline .timeline-enter:nth-of-type(3) {
  animation-delay: 0.6s;
}

.component-timeline .timeline-enter:nth-of-type(4) {
  animation-delay: 0.8s;
}

.component-timeline .timeline-enter:nth-of-type(5) {
  animation-delay: 1s;
}

.component-timeline .timeline-enter:nth-of-type(6) {
  animation-delay: 1.2s;
}

.component-timeline .timeline-enter:nth-of-type(7) {
  animation-delay: 1.4s;
}

@media (max-width: 991px) {
  .component-timeline .timeline-enter {
    animation: none;
  }
}

.component-timeline .timeline-leave {
  animation: 0.5s fadeInUp ease;
}

.component-timeline .timeline-card {
  flex-basis: 350px;
  transition: flex-basis 0.5s ease, min-width 0.5s ease;
  position: relative;
  overflow: hidden;
  background: #000;
  border-left: 1px solid white;
  flex-grow: 1;
  min-width: 300px;
}

.component-timeline .timeline-card .image-holder,
.component-timeline .timeline-card .copy-holder {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
}

@media (max-width: 991px) {
  .component-timeline .timeline-card .image-holder,
  .component-timeline .timeline-card .copy-holder {
    display: block;
    position: relative;
  }
}

.component-timeline .timeline-card .image-holder {
  background-position: 50% 50%;
  background-size: auto 100%;
  opacity: 0.7;
  left: 0;
  border-right: 0px solid white;
}

@media (max-width: 991px) {
  .component-timeline .timeline-card .image-holder {
    padding-top: 80vw;
  }
}

.component-timeline .timeline-card .copy-holder {
  display: flex;
  flex-flow: column;
  justify-content: normal;
  right: 0;
}

.component-timeline .timeline-card .copy-holder > * {
  width: 100%;
  text-align: center;
  color: #fff;
}

.component-timeline .timeline-card .copy-holder span.year {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 5.2em;
  margin-top: 18.2vh;
}

@media (max-width: 991px) {
  .component-timeline .timeline-card .copy-holder span.year {
    margin-top: 10.2vh;
  }
}

.component-timeline .timeline-card .copy-holder span.date {
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  margin-top: 0.3vh;
  position: relative;
}

.component-timeline .timeline-card .copy-holder span.date:after {
  content: '';
  position: absolute;
  width: 80px;
  height: 2px;
  background: white;
  left: 50%;
  transform: translateX(-50%);
  bottom: -3.4vh;
}

.component-timeline .timeline-card .copy-holder h1 {
  font-family: "Oswald", sans-serif;
  font-size: 3.2em;
  text-transform: uppercase;
  margin-top: 33.8vh;
  opacity: 0;
  transform: translate3d(0, 5vh, 0);
  transition: all 1s ease;
}

@media (max-width: 991px) {
  .component-timeline .timeline-card .copy-holder h1 {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    margin-top: 11.8vh;
  }
}

.component-timeline .timeline-card .description {
  height: 0;
  overflow: hidden;
  font-size: 2em;
  margin-top: 2.3vh;
  width: 30em;
  margin: 0.8vh auto 0;
  line-height: 1.7;
  opacity: 0;
  max-width: 77%;
}

@media (max-width: 991px) {
  .component-timeline .timeline-card .description {
    height: auto;
    opacity: 1;
    padding-bottom: 5em;
  }
}

@media (min-width: 992px) {
  .component-timeline .timeline-card:hover {
    min-width: 600px;
  }
  .component-timeline .timeline-card:hover h1 {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .component-timeline .timeline-card.is-expanded-false {
    cursor: pointer;
  }
  .component-timeline .timeline-card.is-expanded-false .copy-holder {
    background-color: transparent !important;
  }
  .component-timeline .timeline-card.is-expanded-true {
    flex-basis: 100%;
  }
  .component-timeline .timeline-card.is-expanded-true .image-holder {
    width: 50%;
    border-right: 1px solid white;
    opacity: 1;
  }
  .component-timeline .timeline-card.is-expanded-true:hover .image-holder {
    transform: scale(1) translate3d(0);
    transition: all 0.5s ease;
  }
  .component-timeline .timeline-card.is-expanded-true .copy-holder {
    width: 50%;
  }
  .component-timeline .timeline-card.is-expanded-true .copy-holder span.year {
    font-size: 7.2em;
    margin-top: 15.7vh;
  }
  .component-timeline .timeline-card.is-expanded-true .copy-holder span.date {
    font-size: 2.4em;
    margin-top: 1vh;
  }
  .component-timeline .timeline-card.is-expanded-true .copy-holder h1 {
    font-size: 3.8em;
    margin-top: 8.8vh;
  }
  .component-timeline .timeline-card.is-expanded-true .copy-holder .description {
    height: auto;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .component-timeline .timeline-navigation {
    display: none;
  }
}

.component-timeline .timeline-navigation a {
  display: inline-block;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
  opacity: 0;
  cursor: pointer;
  text-indent: -9999px;
  transition: all 0.4s ease;
}

.component-timeline .timeline-navigation a.active {
  opacity: 1;
}

.component-timeline .timeline-navigation a.close {
  top: 51px;
  right: 53px;
  width: 1.5em;
  height: 1.5em;
  background-image: url(../images/icons/icon-close--white.svg);
  font-size: 1.6em;
}

.component-timeline .timeline-navigation a.close:hover {
  transform: scale(1.2);
}

.component-timeline .timeline-navigation a.arrow {
  top: 50%;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  background-image: url(../images/icons/icon-carousel-nav.svg);
  font-size: 5.6em;
  margin-top: -0.75em;
}

.component-timeline .timeline-navigation a.arrow:hover {
  background-position: 80% 50%;
}

.component-timeline .timeline-navigation a.arrow.bob-true {
  animation-name: bob-float, bob;
  animation-duration: .3s, 1.5s;
  animation-delay: 0s, .3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}

.component-timeline .timeline-navigation a.nav-forward {
  left: auto;
  right: 24px;
}

.component-timeline .timeline-navigation a.nav-back {
  transform: scale(-1);
  left: 24px;
  text-indent: 9999px;
}

@media (min-width: 992px) {
  .component-timeline.scroll-enabled-true .timeline-navigation a.arrow.nav-forward {
    opacity: 1;
  }
  .component-timeline.item-open-true .timeline-card {
    transition: none;
  }
  .component-timeline.item-open-true .timeline-card.is-expanded-false {
    flex-basis: 0px;
    min-width: 0;
    border: 0;
  }
  .component-timeline.item-open-true .timeline-card.is-expanded-false .copy-holder {
    opacity: 0;
  }
  .component-timeline.item-open-true .timeline-card.is-expanded-true .copy-holder > * {
    opacity: 0;
  }
  .component-timeline.item-open-true .timeline-card.is-expanded-true.animateIn .copy-holder > * {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInUp;
  }
  .component-timeline.item-open-true .timeline-navigation a {
    opacity: 1;
  }
}
